<template>
  <div class="pages scroll-none" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <router-link to="/secondsKillEdit"
                         v-slot="{ href, route, navigate, isActive, isExactActive }">
              <el-button class=""
                         type="primary"
                         :active="isActive"
                         :href="href"
                         @click="navigate">新增活动</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:table="row">
        <el-table :data="tableData"
                  :height="row.heightText"
                  v-if="row.height">
          <el-table-column prop="id"
                           label="活动ID"
                           width="180">
          </el-table-column>
          <el-table-column prop="name"
                           label="活动名称"
                           width="200">
          </el-table-column>
          <el-table-column prop="payFee"
                           label="订单实付金额(元)"
                           width="160">
          </el-table-column>
          <el-table-column prop="payOrderNum"
                           label="支付订单数"
                           width="160">
          </el-table-column>
          <el-table-column prop="name"
                           label="活动时间"
                           width="340">
            <template slot-scope="scope">
              {{scope.row.startTime_text}} 至 {{scope.row.endTime_text}}
            </template>
          </el-table-column>
          <el-table-column prop="name"
                           label="活动状态"
                           width="200">
            <template slot-scope="scope">
              {{secondsKillStatus[scope.row.status]||`未知【${scope.row.status}】`}}
            </template>
          </el-table-column>
          <el-table-column width="240"
                           fixed="right">

            <template slot-scope="scope">

              <el-popover class="margin-right-10"
                          placement="left"
                          width="400"
                          trigger="click">
                <div class="seek-data">
                  <div class="seek-data-row title">{{scope.row.name}}</div>
                  <div class="seek-data-row flex-item">
                    <div class="content seek-data-item">
                      <div class="text">
                        订单实付金额(元)
                        <el-popover placement="top-start" width="200" trigger="hover"
                                    title="订单实付金额(元)"
                                    content="当前限时秒杀活动的订单总付款金额（含退款订单）">
                          <el-icon class="el-icon-info" slot="reference"></el-icon>
                        </el-popover>
                      </div>
                      <div class="number">{{scope.row.payFee}}</div>
                    </div>
                    <div class="content seek-data-item">
                      <div class="text">
                        优惠总金额(元)
                        <el-popover placement="top-start" width="200" trigger="hover"
                                    title="优惠总金额(元)"
                                    content="限时秒杀活动优惠总金额">
                          <el-icon class="el-icon-info" slot="reference"></el-icon>
                        </el-popover>
                      </div>
                      <div class="number">{{scope.row.discountFee}}</div>
                    </div>
                  </div>
                  <div class="seek-data-row flex-item">
                    <div class="content seek-data-item">
                      <div class="text">
                        支付订单数
                        <el-popover placement="bottom" width="200" trigger="hover"
                                    title="支付订单数"
                                    content="限时秒杀活动已支付订单笔数">
                          <el-icon class="el-icon-info" slot="reference"></el-icon>
                        </el-popover>
                      </div>
                      <div class="number">{{scope.row.payOrderNum}}</div>
                    </div>
                    <div class="content seek-data-item">
                      <div class="text">
                        付款商品件数
                        <el-popover placement="bottom" width="200" trigger="hover"
                                    title="付款商品件数"
                                    content="参加限时秒杀活动购买的商品件数">
                          <el-icon class="el-icon-info" slot="reference"></el-icon>
                        </el-popover>
                      </div>
                      <div class="number">{{scope.row.payGoodsNum}}</div>
                    </div>
                  </div>
                </div>
                <el-button type="primary" size="mini" slot="reference">数据</el-button>
              </el-popover>
              <router-link :to="'/secondsKillEdit?id='+scope.row.id"
                           v-slot="{ href, route, navigate, isActive, isExactActive }">
                <el-button class=""
                           type=""
                           size="mini"
                           :active="isActive"
                           :href="href"
                           @click="navigate">{{[1,2].includes(scope.row.status) ? '编辑' : '查看'}}</el-button>
              </router-link>
              <el-button type="warning" size="mini"
                         @click="closeItem(scope.row)"
                         v-if="[1,2].includes(scope.row.status)">失效</el-button>
              <el-button type="danger" size="mini"
                         @click="deleteItem(scope.row)"
                         v-else>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData,parseTimeArray} from "../../../assets/js/utils";
  import {secondsKillStatus,secondsKillStatusArray} from "../../../assets/js/status";

  export default {
    name: "secondsKill",
    data() {
      return {
        loading: false,
        /* 状态 */
        secondsKillStatus,
        secondsKillStatusArray,
        /* 筛选 */
        searchKeyType:[
          {id: 'name', name: '活动名称'},
        ],
        searchData:{
          searchType:'name',
          searchValue:'',
        },
        /* 表格 */
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数
      }
    },
    created(){
      this.getList();
    },
    methods: {
      /**
       * 拼接搜索参数
       */
      jointSearchData() {
        let data = {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
        };
        data = Object.assign(data, this.searchData);
        disposeSearchData(data);
        return data;
      },
      /**
       * 搜索
       */
      search(type) {
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList() {
        let data = this.jointSearchData();
        this.loading = true;
        return this.axios.get(URL.supplierSpikeGoods.list, {params: data}).then(res => {
          this.loading = false;
          parseTimeArray(res.data.records, ["startTime"],'YYYY-MM-DD HH:mm');
          parseTimeArray(res.data.records, ["endTime"],'MM-DD HH:mm');
          this.totalListNum = parseInt(res.data.total);
          this.tableData = res.data.records;
          return Promise.resolve(res);
        }).catch(res => {
          this.loading = false;
          this.tableData = [];
          // return Promise.reject(res);
        });
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val, type) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val, type) {
        this.currentPage = val;
        this.getList();
      },
      /**
       * 关闭秒杀活动
       */
      closeItem(row){
        return this.$confirm('确定失效该活动吗？失效后将无法恢复！','失效',{
          type: 'error'
        }).then(()=>{
          let data = {
            id:row.id,
          };
          this.loading = true;
          return this.axios.post(URL.supplierSpikeGoods.updateInvalidation,data)
        }).then(res=>{
          this.loading = false;
          this.getList();
        }).catch(res=>{
          this.loading = false;
        })
      },
      /**
       * 删除秒杀活动
       */
      deleteItem(row){
        return this.$confirm('确定删除该活动吗？删除后将无法恢复！','删除',{
          type: 'error'
        }).then(()=>{
          let data = {
            id:row.id,
          };
          this.loading = true;
          return this.axios.post(URL.supplierSpikeGoods.delete,data)
        }).then(res=>{
          this.loading = false;
          this.getList();
        }).catch(res=>{
          this.loading = false;
        })
      },
    },
  }
</script>

<style scoped lang="less">
  @import "../../../assets/css/data";
  .seek-data{

  }
  .seek-data-row{
    margin-bottom: 20px;
  }
  .seek-data-item{
    .text{
      color: @color-info;
    }
    .number{
      margin-top: 10px;
      font-size: 18px;
    }
  }
</style>
